/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/core"

import { mediaQueryList } from "../helpers"

import Fonts from "./fonts"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const NavigationContext = React.createContext({})

const Layout = ({ children }) => {
  const [navigationOpen, setNavigationState] = React.useState(false)
  const navigationOpenClassName = "navigation-open"

  React.useEffect(() => {
    if (navigationOpen) {
      document.documentElement.classList.add(navigationOpenClassName)
    } else {
      document.documentElement.classList.remove(navigationOpenClassName)
    }

    return () => {
      document.documentElement.classList.remove(navigationOpenClassName)
    }
  }, [navigationOpen])

  return (
    <NavigationContext.Provider value={[navigationOpen, setNavigationState]}>
      <Fonts />
      <Global
        styles={css`
          .${navigationOpenClassName} {
            overflow: hidden;
          }

          ${mediaQueryList()[2]} {
            .${navigationOpenClassName} {
              overflow: auto;
            }
          }
        `}
      />
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          "& *:focus": {
            outline: 0,
          },
        }}
      >
        <Header />
        <div>
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </NavigationContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { NavigationContext }

export default Layout
