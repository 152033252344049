/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Flex } from "@theme-ui/components"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion"

import { mediaQueryList, renderLineBreak } from "../helpers"

import { NavigationContext } from "./layout"
import PageLink from "./page-link"

import plantPrefabLogo from "../images/plant-prefab-logo.svg"

const dropdownContainerSelector = "header-dropdown-container"
const styleList = {
  heading: {
    fontSize: 4,
    mb: 1,
  },
  listItem: {
    color: ["secondary", "secondary", "secondary", "text"],
    cursor: ["pointer", "pointer", "pointer", "default"],
    fontSize: [3, 3, 3, 2],
    mb: 0,
    mr: [0, 0, 0, 5],
    position: "relative",
    [`${mediaQueryList()[2]}`]: {
      [`& > .${dropdownContainerSelector}`]: { display: "none" },
      "&:hover": {
        [`& > .${dropdownContainerSelector}`]: { display: "block" },
      },
      ":last-child": { mr: 0 },
    },
  },
  listItemText: {
    display: "block",
    fontFamily: "heading",
    px: [5, 5, 5, 0],
    py: [3, 3, 3, 0],
  },
  linkItem: {
    color: ["secondary", "secondary", "secondary", "text"],
    textDecoration: "none",
  },
  listContainer: {
    listStyle: "none",
    m: 0,
    minWidth: 300,
    p: 0,
  },
  placeholderImage: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    border: "1px solid #eee",
    borderRadius: "100%",
    flexShrink: 0,
    height: 50,
    width: 50,
  },
}

const HeaderContentCard = ({
  asset,
  body,
  heading,
  link,
  linkText,
  type,
  image,
}) => {
  const componentType = type || "primary"
  const componentStyle = {
    componentWrapper: {
      primary: {
        alignItems: "center",
        display: "flex",
        mb: 3,
      },
    },
    asset: {
      primary: {
        ...styleList.placeholderImage,
        backgroundImage: image ? `url(${image.src})` : null,
      },
      secondary: {
        ...styleList.placeholderImage,
        backgroundImage: image ? `url(${image.src})` : null,
        borderRadius: 0,
        height: 120,
        width: "100%",
      },
    },
    contentWrapper: {
      primary: { ml: [0, 0, 0, 3] },
      secondary: { mt: 4 },
    },
    body: {
      primary: { fontSize: 2, color: "tertiary" },
      secondary: { fontSize: 2, mb: 2 },
    },
  }

  const componentContent = (
    <React.Fragment>
      {asset && (
        <div
          sx={{
            ...componentStyle.asset[componentType],
            display: ["none", "none", "none", "block"],
          }}
        />
      )}
      <div sx={componentStyle.contentWrapper[componentType]}>
        <h4
          sx={{
            fontSize: [2, 2, 2, 3],
            fontWeight: ["body", "body", "body", "heading"],
            mb: 1,
            whiteSpace: "nowrap",
          }}
        >
          {heading}
        </h4>
        <p sx={componentStyle.body[componentType]}>{renderLineBreak(body)}</p>
        {componentType === "secondary" && link && linkText && (
          <PageLink
            customStyle={{ color: "primary" }}
            link={link}
            text={`${linkText} >`}
            type="internal"
          />
        )}
      </div>
    </React.Fragment>
  )

  return componentType === "primary" && link ? (
    <li>
      <Link
        sx={{
          color: "inherit",
          textDecoration: "none",
          ...componentStyle.componentWrapper[componentType],
        }}
        to={link}
      >
        {componentContent}
      </Link>
    </li>
  ) : (
    <li sx={componentStyle.componentWrapper[componentType]}>
      {componentContent}
    </li>
  )
}
const HeaderDropdownContainer = ({ children, customStyle }) => (
  <div
    className={dropdownContainerSelector}
    sx={{
      backgroundColor: ["#eff1fd", "#eff1fd", "#eff1fd", "transparent"],
      fontFamily: "body",
      [`${mediaQueryList()[2]}`]: {
        left: "50%",
        position: "absolute",
        pt: "31px",
        top: 27,
        transform: "translateX(-50%)",
        zIndex: 1,
      },
      ...customStyle,
    }}
  >
    <Flex
      sx={{
        [`${mediaQueryList()[2]}`]: {
          bg: "white",
          borderRadius: 5,
          boxShadow: "0px 0px 27px 0px rgba(0, 0, 0, 0.26)",
        },
        px: [5, 5, 5, 4, 4, 4],
        py: [3, 3, 3, 4, 4, 4],
      }}
    >
      {children}
    </Flex>
  </div>
)
const HeaderFeaturedProject = ({ name, location, image }) => (
  <div
    sx={{
      backgroundImage: `url(${image})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      height: 130,
      position: "relative",
      width: [200, 200, 200, 200, 200, 250],
    }}
  >
    <div
      sx={{
        color: "white",
        fontSize: 1,
        padding: 2,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: 175,
      }}
    >
      <p sx={{ lineHeight: 1, mb: 1 }}>{name}</p>
      <p sx={{ lineHeight: 1 }}>{location}</p>
    </div>
  </div>
)
const HeaderLinkList = ({ customStyle, heading, linkList, type }) => {
  const componentType = type || "primary"
  const componentStyle = {
    wrapper: {
      primary: {
        ...styleList.listContainer,
        borderLeft: "1px solid #e4e4e4",
        minWidth: 200,
        pl: 4,
        py: 0,
      },
      tertiary: {
        ...styleList.listContainer,
        minWidth: 135,
      },
    },
    heading: {
      primary: { fontWeight: "heading" },
      secondary: {
        color: "#999999",
        fontSize: 1,
        fontWeight: "body",
        lineHeight: 1,
        textTransform: "uppercase",
      },
    },
    link: {
      primary: { fontSize: 1 },
      tertiary: {
        color: "text",
        fontFamily: "heading",
        fontSize: 3,
        fontWeight: "heading",
      },
    },
  }

  return (
    <ul
      sx={{
        ...(componentType === "secondary"
          ? {
              ...componentStyle.wrapper.primary,
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
            }
          : componentStyle.wrapper[componentType]),
        ...customStyle,
      }}
    >
      {heading && (
        <li
          sx={{
            fontSize: 1,
            mb: 1,
            ...componentStyle.heading[componentType],
          }}
        >
          {heading}
        </li>
      )}
      {linkList.map(linkItem => (
        <li key={linkItem.id}>
          <Link
            sx={{
              color: "primary",
              fontFamily: "heading",
              fontSize: 2,
              textDecoration: "none",
              ...componentStyle.link[componentType],
            }}
            to={linkItem.link}
          >
            {linkItem.text || linkItem.component}
          </Link>
        </li>
      ))}
    </ul>
  )
}
const HeaderNavigationItem = ({ isActionable, link, text }) => (
  <React.Fragment>
    {!isActionable && text && <span sx={styleList.listItemText}>{text}</span>}
    {isActionable && link && text && (
      <Link sx={styleList.linkItem} to={link}>
        <span sx={styleList.listItemText}>{text}</span>
      </Link>
    )}
  </React.Fragment>
)
const HeaderMenuItem = ({ isAccordion, heading, panel }) => {
  return isAccordion ? (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>{heading}</AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>{panel}</AccordionItemPanel>
    </AccordionItem>
  ) : (
    <React.Fragment>
      {heading}
      {panel}
    </React.Fragment>
  )
}
const HeaderNavigation = ({ isSmallResolution, isOpen }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulProject(limit: 2, sort: { fields: createdAt, order: DESC }) {
        edges {
          node {
            id
            slug
            projectImages {
              fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid_withWebp
              }
              id
              title
            }
            projectTitle {
              projectTitle
            }
            projectLocation {
              projectLocation
            }
          }
        }
      }

      modelsImage: file(relativePath: { eq: "nav-model-image-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      projectsImage: file(relativePath: { eq: "nav-project-image-01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const projects = data.allContentfulProject.edges.map(project => {
    return {
      link: `projects/${project.node.slug}`,
      id: project.node.id,
      component: (
        <HeaderFeaturedProject
          name={project.node.projectTitle.projectTitle}
          location={project.node.projectLocation.projectLocation}
          image={project.node.projectImages[0].fluid.src}
        />
      ),
    }
  })

  const linkList = {
    company: [
      {
        id: "4c560196-931c-461b-b2e6-1f481e09909e",
        link: "/about",
        text: "About",
      },
      {
        id: "6df3a2c6-a24f-471a-8be2-51e09aa2ee21",
        link: "/careers",
        text: "Careers",
      },
      {
        id: "fc21c05f-29ae-4302-b447-6f6576145cc0",
        link: "/newsroom",
        text: "News",
      },
      {
        id: "cdd13338-ac73-4710-ba86-1cc47b35a283",
        link: "/newsroom",
        text: "Events",
      },
      {
        id: "c306c1ee-715b-462e-a050-242504aa321e",
        link: "/newsroom",
        text: "Tours & Events",
      },
    ],
    featuredProjects: projects,
    models: [
      {
        id: "0b0356c9-ba4d-434e-b4e4-67db8b9ba112",
        link: "/",
        text: "Custom Design",
      },
      {
        id: "7563908a-61d2-4fba-8f1d-8d4b0ffae08d",
        link: "/",
        text: "Architectural design services",
      },
      {
        id: "b82e123c-7b68-4015-9dfd-6f0fb7f9d742",
        link: "/",
        text: "Custom Construction",
      },
      {
        id: "c9ac17fb-e4ea-4610-ba7a-a7fde781ac63",
        link: "/",
        text: "Efficient prefabrication of any design",
      },
      {
        id: "40d5a9b5-e382-455e-9d2b-3462404a1335",
        link: "/",
        text: "Wildfire Rebuild",
      },
      {
        id: "Special-support",
        link: "/",
        text: "Special support for rebuilding",
      },
    ],
    workingWithUs: [
      {
        id: "a3c3eb9c-f6fe-47bf-b27a-15f56580f8e6",
        link: "/homebuyers",
        text: "Homebuyers",
      },
      {
        id: "c020fb08-f3f9-45f4-b992-04c173b14b09",
        link: "/developers",
        text: "Developers",
      },
      {
        id: "1b9473a3-121f-4c5a-80a2-d45b8d9bee08",
        link: "/architects-and-designers",
        text: "Architects",
      },
    ],
  }
  const navigationContent = customStyle => (
    <ul
      sx={{
        borderTop: [
          "1px solid #eee",
          "1px solid #eee",
          "1px solid #eee",
          "none",
        ],
        display:
          isSmallResolution && isOpen
            ? ["flex", "flex", "flex", "none"]
            : ["none", "none", "none", "flex"],
        flexDirection: ["column", "column", "column", "row"],
        listStyle: "none",
        m: 0,
        marginLeft: [0, 0, 0, "auto"],
        width: ["100%", "100%", "100%", "auto"],
        ...customStyle,
      }}
    >
      <li sx={styleList.listItem}>
        <HeaderNavigationItem
          isActionable
          link="/advantages"
          text="Advantages"
        />
      </li>
      <li
        sx={{
          ...styleList.listItem,
          order: [1, 1, 1, "initial"],
        }}
      >
        <HeaderMenuItem
          isAccordion={isSmallResolution}
          heading={<HeaderNavigationItem text="Services" />}
          panel={
            <HeaderDropdownContainer>
              <ul
                sx={{
                  ...styleList.listContainer,
                  pr: [0, 0, 0, 4],
                }}
              >
                <HeaderContentCard
                  body="Standard home designs"
                  heading="LivingHomes"
                  link="/livinghomes"
                />
                <HeaderContentCard
                  body="Architectural design services"
                  heading="Custom Design"
                  link="/custom-design"
                />
                <HeaderContentCard
                  body="Efficient prefabrication of any design"
                  heading="Custom Construction"
                  link="/custom-construction"
                />
                <HeaderContentCard
                  body="Special support for rebuilding"
                  heading="Wildfire Rebuild"
                  link="/wildfire-help"
                />
              </ul>
              <HeaderLinkList
                customStyle={{
                  display: ["none", "none", "none", "block"],
                }}
                heading="Working With Us"
                linkList={linkList.workingWithUs}
                type="secondary"
              />
            </HeaderDropdownContainer>
          }
        />
      </li>
      <li sx={styleList.listItem}>
        <HeaderNavigationItem
          isActionable={isSmallResolution}
          link="/models"
          text="Models"
        />
        <HeaderDropdownContainer
          customStyle={{ display: ["none", "none", "none", "block"] }}
        >
          <ul
            sx={{
              ...styleList.listContainer,
              pr: 4,
            }}
          >
            <HeaderContentCard
              asset
              body={"View our collection of customizable home designs."}
              heading="LivingHomes"
              image={data.modelsImage.childImageSharp.fluid}
              link="/models"
              linkText="Browse Designs"
              type="secondary"
            />
          </ul>
          <HeaderLinkList
            heading="Browse By"
            linkList={linkList.models}
            type="secondary"
          />
        </HeaderDropdownContainer>
      </li>
      <li sx={styleList.listItem}>
        <HeaderNavigationItem
          isActionable={isSmallResolution}
          text="Gallery"
          link="/projects"
        />
        <HeaderDropdownContainer
          customStyle={{
            display: ["none", "none", "none", "block"],
            left: ["auto", "auto", "0 !important"],
            transform: ["none", "none", "translateX(-55%) !important"],
          }}
        >
          <ul
            sx={{
              ...styleList.listContainer,
              pr: 4,
            }}
          >
            <HeaderContentCard
              asset
              body={
                "Our completed projects speak for\nthemselves — sustainable, beautiful."
              }
              heading="Gallery"
              image={data.projectsImage.childImageSharp.fluid}
              link="/projects"
              linkText="View completed projects"
              type="secondary"
            />
          </ul>
          <HeaderLinkList
            heading="Featured Projects"
            linkList={linkList.featuredProjects}
            type="secondary"
          />
        </HeaderDropdownContainer>
      </li>
      <li
        sx={{
          ...styleList.listItem,
          display: ["block", "block", "block", "none"],
          order: [2, 2, 2, "initial"],
        }}
      >
        <HeaderMenuItem
          isAccordion={isSmallResolution}
          heading={<HeaderNavigationItem text="Working With Us" />}
          panel={
            <HeaderDropdownContainer>
              <HeaderLinkList
                linkList={linkList.workingWithUs}
                type="tertiary"
              />
            </HeaderDropdownContainer>
          }
        />
      </li>
      <li
        sx={{
          ...styleList.listItem,
          order: [3, 3, 3, "initial"],
        }}
      >
        <HeaderMenuItem
          isAccordion={isSmallResolution}
          heading={<HeaderNavigationItem text="Company" />}
          panel={
            <HeaderDropdownContainer>
              <HeaderLinkList linkList={linkList.company} type="tertiary" />
            </HeaderDropdownContainer>
          }
        />
      </li>
      <li sx={styleList.listItem}>
        <Link sx={styleList.linkItem} to="/contact">
          <span sx={styleList.listItemText}>Contact</span>
        </Link>
      </li>
    </ul>
  )

  return isSmallResolution ? (
    <Accordion
      sx={{ display: ["block", "block", "block", "none"], width: "100%" }}
    >
      {navigationContent()}
    </Accordion>
  ) : (
    navigationContent({ display: ["none", "none", "none", "flex"] })
  )
}

const Header = () => {
  const [navigationOpen, setNavigationState] = React.useContext(
    NavigationContext
  )
  const headerStyle = {
    bg: "white",
    backgroundImage: "linear-gradient(180deg, #fefefe, #ebebeb)",
  }

  return (
    <header
      sx={
        navigationOpen
          ? {
              ...headerStyle,
              height: ["100vh", "100vh", "100vh", "auto"],
              overflowY: ["auto", "auto", "auto", "visible"],
              position: ["fixed", "fixed", "fixed", "static"],
              width: ["100%", "100%", "100%", "auto"],
              zIndex: [1, 1, 1, 1],
            }
          : headerStyle
      }
    >
      <Flex
        sx={{
          alignItems: ["flex-start", "flex-start", "flex-start", "center"],
          flexDirection: ["column", "column", "column", "row"],
          maxWidth: 1352,
          mx: "auto",
          px: [0, 0, 0, 20],
        }}
      >
        <div
          sx={{
            alignItems: "center",
            display: "flex",
            height: theme => theme.sizes.header.height,
            px: [20, 20, 20, 0],
            width: ["100%", "100%", "100%", "auto"],
          }}
        >
          <Link
            sx={{
              display: "block",
              fontSize: "0px",
              lineHeight: 1,
            }}
            to="/"
          >
            <img
              sx={{
                height: 46,
                width: 106,
                m: 0,
              }}
              src={plantPrefabLogo}
              alt="Plant Prefab Logo"
            />
          </Link>
          <div
            sx={{
              display: ["block", "block", "block", "none"],
              ml: "auto",
            }}
          >
            <button
              sx={{
                background: "none",
                border: "none",
                cursor: "pointer",
                display: "block",
                p: 0,
              }}
              type="button"
              onClick={() => setNavigationState(!navigationOpen)}
            >
              {!navigationOpen && (
                <React.Fragment>
                  <div
                    sx={{
                      backgroundColor: "text",
                      height: 3,
                      mb: 1,
                      width: 25,
                    }}
                  />
                  <div
                    sx={{
                      backgroundColor: "text",
                      height: 3,
                      mb: 1,
                      width: 25,
                    }}
                  />
                  <div
                    sx={{
                      backgroundColor: "text",
                      height: 3,
                      width: 25,
                    }}
                  />
                </React.Fragment>
              )}
              {navigationOpen && (
                <React.Fragment>
                  <div
                    sx={{
                      backgroundColor: "text",
                      height: 3,
                      width: 25,
                      transform: "rotate(45deg)",
                    }}
                  />
                  <div
                    sx={{
                      backgroundColor: "text",
                      height: 3,
                      position: "relative",
                      top: "-3px",
                      transform: "rotate(-45deg)",
                      width: 25,
                    }}
                  />
                </React.Fragment>
              )}
            </button>
          </div>
        </div>
        <HeaderNavigation />
        <HeaderNavigation isOpen={navigationOpen} isSmallResolution />
      </Flex>
    </header>
  )
}

export default Header
