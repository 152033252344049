/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import PageLink from "./page-link"

const CTA = ({ children, customStyle, link, linkType, type }) => {
  const componentType = type || "primary"
  const typeStyleList = {
    primary: { chevron: { right: 20 } },
    secondary: { chevron: { right: 20 } },
    tertiary: { chevron: { right: 15 } },
    alt: {
      bg: "transparent",
      borderColor: "secondary",
      color: "secondary",
      chevron: { right: 15 },
    },
  }

  return (
    <PageLink
      customStyle={{
        bg: componentType === "alt" ? "transparent" : "primary",
        borderRadius: 9999,
        color: "white",
        cursor: "pointer",
        display: "inline-block",
        fontFamily: "body",
        lineHeight: 1,
        position: "relative",
        textAlign: "left",
        ":hover": {
          bg:
            (typeStyleList[componentType] && typeStyleList[componentType].bg) ||
            "secondary",
          borderColor:
            (typeStyleList[componentType] &&
              typeStyleList[componentType].borderColor) ||
            null,
          color:
            (typeStyleList[componentType] &&
              typeStyleList[componentType].color) ||
            null,
        },
        variant: `buttons.${componentType}`,
        ...customStyle,
      }}
      link={link}
      type={linkType}
    >
      <span>{children}</span>
      <span
        sx={
          typeStyleList[componentType] &&
          typeStyleList[componentType].chevron &&
          Object.assign(
            {
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            },
            typeStyleList[componentType].chevron
          )
        }
      >
        {">"}
      </span>
    </PageLink>
  )
}

CTA.propTypes = {
  children: PropTypes.node.isRequired,
  customStyle: PropTypes.object,
  link: PropTypes.string,
  linkType: PropTypes.oneOf(["internal"]),
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "alt"]),
}

export default CTA
