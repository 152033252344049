/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import PageLink from "./page-link"
import Row from "../components/row"
import Text from "../components/text"

import plantPrefabLogo from "../images/plant-prefab-logo.svg"

const footerListItemStyle = {
  mr: 3,
  mb: 0,
  ":last-child": { mr: 0 },
}
const footerLinkList = [
  {
    section: "Plant Prefab",
    id: "PlantPrefabColumn",
    links: [
      {
        name: "Advantages",
        id: "AdvantagesLink",
        href: "/advantages",
      },
      {
        name: "Models",
        id: "ModelsLink",
        href: "/models",
      },
      {
        name: "Gallery",
        id: "GalleryLink",
        href: "/projects",
      },
      {
        name: "Contact",
        id: "ContractLink",
        href: "/contact",
      },
    ],
  },
  {
    section: "Services",
    id: "ServicesColumn",
    links: [
      {
        name: "LivingHomes",
        id: "LivingHomesLink",
        href: "/livinghomes",
      },
      {
        name: "Custom Design",
        id: "CustomDesignLink",
        href: "/custom-design",
      },
      {
        name: "Custom Build",
        id: "CustomBuildLink",
        href: "/custom-build",
      },
      {
        name: "Wildfire Rebuild",
        id: "WildfireRebuildLink",
        href: "/wildfire-help",
      },
    ],
  },
  {
    section: "Working With Us",
    id: "WorkingWithUsColumn",
    links: [
      {
        name: "Homebuyers",
        id: "HomebuyersLink",
        href: "/homebuyers",
      },
      {
        name: "Developers",
        id: "DevelopersLink",
        href: "/developers",
      },
      {
        name: "Architects",
        id: "ArchitectsLink",
        href: "/architects",
      },
      {
        name: "Contractors",
        id: "ContractorsLink",
        href: "/contractors",
      },
    ],
  },
  /* {
    section: "About Us",
    id: "AboutUsColumn",
    links: [
      {
        name: "Company",
        id: "CompanyLink",
        href: "/company",
      },
      {
        name: "Careers",
        id: "CareersLink",
        href: "/careers",
      },
      {
        name: "Newsroom",
        id: "NewsroomLink",
        href: "/newsroom",
      },
      {
        name: "Tours & Events",
        id: "ToursEventsLink",
        href: "/",
      },
    ],
  }, */
]

const Footer = () => (
  <footer>
    <div sx={{ backgroundColor: "muted" }}>
      <Container>
        <Row customStyle={{ py: theme => theme.spacing.vertical.lg }}>
          <Column size={[12, 12, 4]}>
            <PageLink
              link="/"
              type="internal"
              customStyle={{ mb: theme => theme.spacing.vertical.md }}
            >
              <img
                sx={{
                  height: 59,
                  width: 136,
                  m: 0,
                }}
                src={plantPrefabLogo}
                alt="Plant Prefab Logo"
              />
            </PageLink>
            <Text customStyle={{ fontWeight: "bold" }}>
              Better homes. Built better.
            </Text>
            <Text customStyle={{ mb: theme => theme.spacing.vertical.md }}>
              Custom. Easy. Inspired.
            </Text>
            <div>
              <CTA link="/contact" linkType="internal" type="primary">
                Contact Us
              </CTA>
            </div>
          </Column>
          {footerLinkList.map(column => (
            <Column size={[12, 12, 2]} key={column.id}>
              <Text
                customStyle={{
                  color: "#A6A6A6",
                  mt: [
                    theme => theme.spacing.vertical.md,
                    theme => theme.spacing.vertical.md,
                    0,
                  ],
                  textTransform: "uppercase",
                }}
              >
                {column.section}
              </Text>
              <ul sx={{ listStyle: "none", m: 0 }}>
                {column.links.map(link => (
                  <li sx={{ m: 0 }} key={link.id}>
                    <Link sx={{ textDecoration: "none" }} to={link.href}>
                      <Text
                        customStyle={{
                          fontSize: 3,
                          mt: theme => theme.spacing.vertical.md,
                        }}
                      >
                        {link.name}
                      </Text>
                    </Link>
                  </li>
                ))}
              </ul>
            </Column>
          ))}
        </Row>
      </Container>
    </div>
    <div sx={{ backgroundColor: theme => theme.colors.backgrounds.primary }}>
      <Container>
        <Row>
          <Column customStyle={{ py: 3 }}>
            <ul sx={{ display: "flex", listStyle: "none", margin: 0 }}>
              <li sx={footerListItemStyle}>
                <Text customStyle={{ fontSize: 1 }}>
                  © {new Date().getFullYear()} Plant Prefab, Inc. All rights
                  reserved.
                </Text>
              </li>
              <li sx={footerListItemStyle}>
                <Link sx={{ textDecoration: "none" }} to="/privacy-policy">
                  <Text customStyle={{ fontSize: 1 }}>Privacy Policy</Text>
                </Link>
              </li>
              <li sx={footerListItemStyle}>
                <Link sx={{ textDecoration: "none" }} to="/terms-of-service">
                  <Text customStyle={{ fontSize: 1 }}>Terms of Service</Text>
                </Link>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </div>
  </footer>
)

export default Footer
