/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Text from "./text"

const PageLink = ({ children, customStyle, link, text, type, target }) => {
  const pageLinkStyle = {
    color: "secondary",
    fontSize: 2,
    textDecoration: "none",
  }
  const renderPageLinkContent = () =>
    children ? (
      children
    ) : text ? (
      <Text customStyle={{ color: "inherit", fontSize: "inherit" }}>
        {text}
      </Text>
    ) : null

  return type && type === "internal" ? (
    <Link sx={{ ...pageLinkStyle, ...customStyle, pr: 5 }} to={link}>
      {renderPageLinkContent()}
    </Link>
  ) : (
    <a
      sx={{ ...pageLinkStyle, ...customStyle }}
      href={link}
      rel="noopener noreferrer"
      target={target || "_blank"}
    >
      {renderPageLinkContent()}
    </a>
  )
}

PageLink.propTypes = {
  children: PropTypes.node,
  customStyle: PropTypes.object,
  link: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.oneOf(["internal"]),
  target: PropTypes.string,
}

export default PageLink
