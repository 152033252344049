import { breakpointList } from "../helpers"

export default {
  useCustomProperties: false,
  colors: {
    text: "#000",
    primary: "#31aabb",
    secondary: "#13799d",
    tertiary: "#c0b2a5",
    accent: "#045173",
    muted: "#e4e4e0",
    white: "#fff",
    error: "#ff0000",
    backgrounds: {
      primary: "#f3f4ef",
    },
    borders: {
      muted: "#d9d1c9",
      error: "#ff0000",
    },
  },
  fonts: {
    body: "Helvetica Neue, sans-serif",
    heading: "Helvetica Neue, sans-serif",
  },
  fontWeights: {
    body: 400,
    bold: 700,
    heading: 500,
    light: 300,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [0, 12, 15, 18, 20, 24, 40, 56],
  space: [0, 4, 8, 16, 32, 48, 64, 128, 256, 512],
  spacing: {
    horizontal: 20,
    vertical: {
      sm: 30,
      md: 50,
      lg: 100,
      xl: 180,
    },
  },
  buttons: {
    primary: {
      fontSize: 4,
      pl: 30,
      pr: 75,
      py: 15,
    },
    secondary: {
      fontSize: 1,
      pl: 25,
      pr: 75,
      py: 12,
    },
    tertiary: {
      fontSize: 1,
      pl: 20,
      pr: 45,
      py: 2,
    },
    alt: {
      border: theme => `2px solid ${theme.colors.primary}`,
      color: "primary",
      fontSize: 1,
      pl: 25,
      pr: 75,
      py: 10,
    },
  },
  breakpoints: breakpointList,
  sizes: {
    header: {
      height: 90,
    },
  },
  transitions: "0.25s ease-in-out",
  forms: {
    buttonChevron: {
      color: "white",
      fontSize: 3,
      fontFamily: theme => theme.fonts.body,
      pl: 25,
      pr: 20,
      py: 2,
      borderRadius: 9999,
      cursor: "pointer",
      "&::after": {
        content: "'>'",
        ml: 20,
        position: "relative",
        bottom: "1px",
      },
    },
    buttonSelection: {
      color: "white",
      fontSize: 2,
      fontFamily: theme => theme.fonts.body,
      px: 25,
      py: 1,
      borderRadius: 9999,
      cursor: "pointer",
    },
    label: {
      fontFamily: "body",
    },
    radio: {
      fontWeight: "bold",
      color: "muted",
      bg: "muted",
      border: theme => `3px solid ${theme.colors.muted}`,
      "input:checked ~ &": {
        color: "primary",
        bg: "primary",
      },
    },
    input: {
      bg: "muted",
      fontFamily: "body",
      border: theme => `2px solid ${theme.colors.borders.muted}`,
      px: 25,
      borderRadius: 9999,
    },
    inputError: theme => {
      return {
        ...theme.forms.input,
        border: theme => `2px solid ${theme.colors.borders.error}`,
      }
    },
    select: {
      bg: "muted",
      fontFamily: "body",
      border: theme => `2px solid ${theme.colors.borders.muted}`,
      pl: 25,
      pr: 35,
      borderRadius: 9999,
      "& + svg": {
        ml: -35,
      },
    },
    textarea: {
      fontFamily: "body",
      bg: "muted",
      border: theme => `2px solid ${theme.colors.borders.muted}`,
    },
    textareaError: theme => {
      return {
        ...theme.forms.textarea,
        border: theme => `2px solid ${theme.colors.borders.error}`,
      }
    },
  },
}
