import React from "react"
import get from "lodash/get"
import max from "lodash/max"
import min from "lodash/min"

const breakpointList = ["576px", "768px", "992px", "1200px", "1600px"]

/**
 * Return a list of min-width media queries based on project's breakpoint list.
 * @param mediaPrefix If set to false, return media query without "@media" prefix.
 * @returns {string[]}
 */
const mediaQueryList = ({ mediaPrefix = true } = {}) =>
  breakpointList.map(breakpoint => {
    const mediaDefinition = `(min-width: ${breakpoint})`

    return mediaPrefix ? `@media ${mediaDefinition}` : mediaDefinition
  })

/**
 * Return a list of values based on breakpointValue for breakpoints matching
 * responsiveList against project's breakpoint list and a list of values based on
 * defaultValue for those who don't.
 * @param breakpointValue Value to be set if breakpoint is matched.
 * @param defaultValue Value to be set if breakpoint isn't matched.
 * @param responsiveList If it's a populated Array, check for breakpoint matches, otherwise return defaultValue.
 * @returns {(Array|any)} List of values representing breakpointValue/defaultValue or defaultValue.
 */
const getResponsiveValueList = ({
  breakpointValue,
  defaultValue,
  responsiveList,
}) => {
  return responsiveList &&
    Array.isArray(responsiveList) &&
    responsiveList.length
    ? responsiveList.map(breakpointMatch =>
        breakpointMatch ? breakpointValue : defaultValue
      )
    : defaultValue
}

/**
 * Change "\n" occurrences to <br /> tags.
 * @param text
 * @returns {(String|null)} Text with line break tags or null.
 */
const renderLineBreak = text => {
  return text && typeof text === "string"
    ? text.split(/\n|\\n/).reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    : null
}

/**
 * If a custom style definition is passed, merge it with a default setting so it
 * can be used with component's sx prop.
 * @param customStyle
 * @param defaultStyle
 * @returns {Object}
 */
const mergeStyle = (customStyle, defaultStyle = {}) => {
  return customStyle
    ? Object.assign({}, defaultStyle, customStyle)
    : defaultStyle
}

/**
 * Given a data collection and one of its properties containing numeric values,
 * return the minimum and maximum values found, or "Variable"
 * if one of the values equals 10 and scanForVariable parameter is true
 * @param dataList
 * @param dataProperty
 * @param scanForVariable
 * @returns {string}
 */
const getValueRange = (dataList, dataProperty, scanForVariable) => {
  const valueList =
    dataList && dataList.length ? dataList.map(data => data[dataProperty]) : []
  const maxValue = max(valueList)
  const minValue = min(valueList)

  if (scanForVariable && valueList.filter(value => value === 10).length) {
    return "Variable"
  }

  return maxValue !== minValue ? `${minValue}-${maxValue}` : `${minValue}`
}
/**
 * Returns "Variable" if value equals 10, otherwise return the value
 * @param dataList
 * @param dataProperty
 * @returns {string}
 */
const findAndReplaceVariableValue = value => (value === 10 ? "Variable" : value)

/**
 * If data's found, return a parsed Object with ContentfulPage content type's
 * sectionList field information.
 * @param sectionList
 * @param sectionSlug
 * @returns {(Object|null)}
 */
const getContentfulPageSectionListData = ({ sectionList, sectionSlug }) => {
  const sectionData = sectionList.find(
    sectionData => sectionData.slug === sectionSlug
  )
  const sectionDataContent = get(sectionData, "content.internal.content")

  return sectionDataContent ? JSON.parse(sectionDataContent) : null
}

/**
 * Searchs for an object in the list by it's slug
 * @param list
 * @param slug
 * @returns {(Object|null)}
 */
const findBySlug = ({ list, slug }) => {
  return list.find(data => data.slug === slug)
}

/**
 * Returns a background url list, using desktop image as default
 * if mobile is not provided
 *
 * @param {*} { desktopImage, mobileImage }
 * @returns
 */
const getBackgroundList = ({ desktopImage, mobileImage }) => {
  const desktopBackground = desktopImage
    ? `url(${desktopImage.file.url})`
    : theme => theme.colors.white
  const mobileBackground = mobileImage
    ? `url(${mobileImage.file.url})`
    : desktopBackground

  return [mobileBackground, mobileBackground, desktopBackground]
}

export {
  breakpointList,
  findBySlug,
  findAndReplaceVariableValue,
  getBackgroundList,
  getContentfulPageSectionListData,
  getResponsiveValueList,
  getValueRange,
  mediaQueryList,
  mergeStyle,
  renderLineBreak,
}
